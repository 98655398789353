<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-system"></i>{{ $t("Label Settings") }}
    </h2>
    <div>
      <v-row style="margin-top: 20px;">
        <v-col cols="12"
          ><h3 class="tit-section">
            <b>{{ $t("Buttons Setting") }}</b>
          </h3>
          <div style="margin-left: -9px !important;">
            <v-row>
              <v-col cols="12" sm="4">
                <div
                  style="font-size:  var(--font14); color: #001e38; margin: 0 0 6px 14px"
                >
                  <h3>{{ $t("Button 1 (Main)") }}</h3>
                </div>
                <v-select
                  :items="getButtonsOptions"
                  :item-text="'name'"
                  :item-value="'value'"
                  v-model="buttonInfo.main"
                  label=""
                  placeholder="Select (Main)"
                  class="select-column ml-3"
                  outlined
                  dense
                  style="color:black"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <div
                  style="font-size:  var(--font14); color: #001e38; margin: 0 0 6px 14px"
                >
                  <h3>{{ $t("Button 2 (Sub)") }}</h3>
                </div>
                <v-select
                  :items="getButtonsOptions"
                  :item-text="'name'"
                  :item-value="'value'"
                  v-model="buttonInfo.sub"
                  placeholder="Select (Sub)"
                  class="select-column ml-3"
                  outlined
                  dense
                  style="color:black"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <div
                  style="font-size:  var(--font14); color: #001e38; margin: 0 0 6px 14px"
                >
                  <h3>{{ $t("Button 3 (Extend 1)") }}</h3>
                </div>
                <v-select
                  :items="getButtonsOptions"
                  :item-text="'name'"
                  :item-value="'value'"
                  v-model="buttonInfo.extend1"
                  placeholder="Select (Extend 1)"
                  class="select-column ml-3"
                  outlined
                  dense
                  style="color:black"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <div
                  style="font-size:  var(--font14); color: #001e38;margin: 0 0 6px 14px"
                >
                  <h3>{{ $t("Button 4 (Extend 2)") }}</h3>
                </div>
                <v-select
                  :items="getButtonsOptions"
                  :item-text="'name'"
                  :item-value="'value'"
                  v-model="buttonInfo.extend2"
                  placeholder="Select (Extend 2)"
                  class="select-column ml-3"
                  outlined
                  dense
                  style="color:black"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-btn
                @click="saveSelectedOptions"
                class="btn ml-6"
                :disabled="btnDisabled"
                text
                >{{ $t("Save") }}
              </v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
    <h2 class="page-title-bar mt-7">
      <i class="ico ico-system"></i>{{ $t("Label Properties") }}
    </h2>
    <div>
      <v-row style="margin-top: 10px">
        <div class="col-8">
          <div class="searchArea">
            <div class="row">
              <v-text-field
                :label="`${$t('Label Code')}*`"
                :placeholder="`${$t('Input The Label Code')}`"
                class="form-input ml-3 col mandatory upper-case"
                outlined
                dense
                hide-details
                v-model="labelCode"
                clearable
              ></v-text-field>
            </div>
            <div class="col pl-3 pr-0">
              <v-btn
                text
                class="btn type-search ml-3"
                @click="getLabelsTypeInfo"
                :disabled="
                  labelCode === '' ||
                    labelCode === undefined ||
                    labelCode === null
                "
                >{{ $t("Search") }}
              </v-btn>
            </div>
            <div class="col pl-0">
              <v-btn text class="btn type-search ml-3" @click="clearSearch"
                >{{ $t("Clear") }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-row>
    </div>
    <v-dialog v-model="labelDetailsPopup" @click:outside="labelDetailsPopup = false" width="700" persistent>
      <v-card class="popup add_store_popup">
        <v-btn
          icon
          dark
          x-small
          class="cornerClose"
          @click="labelDetailsPopup = false"
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
        <div class="popup-titbox align-items-center">
          <h3 class="page-title-bar">
            <i class="ico ico-template"></i>
            {{ $t("Label Properties") }}
          </h3>
          <div class="spacer"></div>
        </div>
        <div class="v-data-table tbl-type01 mt-6 theme--light">
          <div class="v-data-table__wrapper">
            <table class="table">
              <tr v-for="(value, name) of labelDetails" :key="name">
                <td width="30%">
                  <strong>
                    {{
                      name
                        | camelCaseToProperCase
                        | removeSpacefromCapitalLetter
                    }}
                  </strong>
                </td>

                <td>{{ value }}</td>
              </tr>
            </table>
          </div>
        </div>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon @click="labelDetailsPopup = false" class="btn"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import codes from '@/plugins/codes'
import EventBus from '@/plugins/eventBus'

const requests = {
  getLabelConfig: {
    method: 'get',
    url: '/api/common/labels/button'
  },
  putLabelConfig: {
    method: 'put',
    url: '/api/common/labels/button'
  },
  getlabeltype: {
    method: 'get',
    url: '/api/common/labels/type/one'
  }
}
export default {
  data () {
    return {
      selctBtnOptions: '',
      selctBtnOptionsExtend: null,
      buttonInfo: {
        main: 'ALARM',
        sub: 'ALARM',
        extend1: 'DISABLED',
        extend2: 'DISABLED'
      },
      labelDetails: {
        labelCode: '',
        name: '',
        displayWidth: 0,
        displayHeight: 0,
        totalPage: 0,
        colorType: '',
        resolution: 0,
        nfc: false
      },
      labelDetailsPopup: false,
      labelCode: '',
      btnDisabled: null
    }
  },
  computed: {
    getButtonsOptions () {
      return [
        { name: this.$t('Disabled'), value: 'DISABLED' },
        { name: this.$t('Led Off'), value: 'LED_OFF' },
        { name: this.$t('Alarm'), value: 'ALARM' },
        { name: this.$t('Clear'), value: 'CLEAR' },
        { name: this.$t('Left Page Back'), value: 'LEFT_PAGE_BACK' },
        { name: this.$t('Right Page Back'), value: 'RIGHT_PAGE_BACK' },
        { name: this.$t('Left Page'), value: 'LEFT_PAGE' },
        { name: this.$t('Right Page'), value: 'RIGHT_PAGE' },
        { name: this.$t('Sync Loss Alarm'), value: 'SYNCLOSS_ALARM_1' },
        { name: this.$t('Picking Report'), value: 'PICKING_REPORT' },
        { name: this.$t('Changeable Left Page'), value: 'CHANGEABLE_LEFT_PAGE' },
        { name: this.$t('Changeable Right Page'), value: 'CHANGEABLE_RIGHT_PAGE' }

      ]
    },
    selectMode () {
      return [
        { mode: this.$t('ON'), value: true },
        { mode: this.$t('OFF'), value: false }
      ]
    }
  },
  filters: {
    // Filter definitions
    camelCaseToProperCase (value) {
      return value.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    },
    removeSpacefromCapitalLetter (value) {
      return value.replace(/([A-Z])\s(?=[A-Z])/g, '$1')
    }
  },
  mounted () {
    EventBus.$emit('enableSelectedStores', false)
    this.$store.dispatch('auth/getDisabledBtn', '2601').then(flag => {
      this.btnDisabled = flag
    })
    this.getButtonInfo()
  },
  methods: {
    clearSearch () {
      this.labelCode = ''
    },
    getLabelsTypeInfo () {
      const config = { params: { labelCode: this.labelCode } }
      this.$utils
        .callAxios(
          requests.getlabeltype.method,
          requests.getlabeltype.url,
          config
        )
        .then(res => {
          if (res.status === 200 && res.data) {
            console.log(res)
            this.labelDetailsPopup = true
            const labelData = res.data
            this.labelDetails.labelCode = labelData.labelCode
            this.labelDetails.name = labelData.name
            this.labelDetails.displayWidth = labelData.displayWidth
            this.labelDetails.displayHeight = labelData.displayHeight
            this.labelDetails.totalPage = labelData.totalPage
            this.labelDetails.colorType = labelData.colorType
            this.labelDetails.resolution = labelData.resolution
            this.labelDetails.nfc = labelData.nfc
          }
        })
        .catch(error => {
          this.labelDetails.labelCode = ''
          this.labelDetails.name = ''
          this.labelDetails.displayWidth = ''
          this.labelDetails.displayHeight = ''
          this.labelDetails.totalPage = ''
          this.labelDetails.colorType = ''
          this.labelDetails.resolution = ''
          this.labelDetails.nfc = false
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    getButtonInfo () {
      const config = { params: {} }
      this.$utils
        .callAxios(
          requests.getLabelConfig.method,
          requests.getLabelConfig.url,
          config
        )
        .then(res => {
          console.log(res)
          if (res.status === 200 && res.data && res.data) {
            this.buttonInfo.main = res.data.main
            this.buttonInfo.sub = res.data.sub
            this.buttonInfo.extend1 = res.data.extend1
            this.buttonInfo.extend2 = res.data.extend2
          }
        })
        .catch(error => {
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    saveSelectedOptions () {
      const config = { params: {} }
      this.$utils
        .callAxiosWithBody(
          requests.putLabelConfig.method,
          requests.putLabelConfig.url,
          this.buttonInfo,
          config
        )
        .then(res => {
          EventBus.$emit('messageAlert', this.$t('Updated Successfully'))
          this.getButtonInfo()
        })
        .catch(error => {
          console.warn(`Failed to update. error: ${error}`)
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    }
  }
}
</script>
<style scoped>
.table tr td {
  border: 1px solid #ccc;
  padding: 3px;
  border-collapse: collapse;
}
.popup {
  padding: 20px;
}
.tit-section {
  background-image: linear-gradient(#ececec, #ececec);
  color: rgba(0, 0, 0, 0.7);
}
::v-deep.theme--light.v-select .v-select__selection--comma {
  /* color: #fafafa !important; */
}
::v-deep.v-select.v-input--dense .v-select__selection--comma {
    /* margin: 5px 4px 5px 19px; */
    font-size: var(--font17);
}
</style>
