var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-system" }),
        _vm._v(_vm._s(_vm.$t("Label Settings")) + " ")
      ]),
      _c(
        "div",
        [
          _c(
            "v-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h3", { staticClass: "tit-section" }, [
                  _c("b", [_vm._v(_vm._s(_vm.$t("Buttons Setting")))])
                ]),
                _c(
                  "div",
                  { staticStyle: { "margin-left": "-9px !important" } },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "var(--font14)",
                                  color: "#001e38",
                                  margin: "0 0 6px 14px"
                                }
                              },
                              [
                                _c("h3", [
                                  _vm._v(_vm._s(_vm.$t("Button 1 (Main)")))
                                ])
                              ]
                            ),
                            _c("v-select", {
                              staticClass: "select-column ml-3",
                              staticStyle: { color: "black" },
                              attrs: {
                                items: _vm.getButtonsOptions,
                                "item-text": "name",
                                "item-value": "value",
                                label: "",
                                placeholder: "Select (Main)",
                                outlined: "",
                                dense: ""
                              },
                              model: {
                                value: _vm.buttonInfo.main,
                                callback: function($$v) {
                                  _vm.$set(_vm.buttonInfo, "main", $$v)
                                },
                                expression: "buttonInfo.main"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "var(--font14)",
                                  color: "#001e38",
                                  margin: "0 0 6px 14px"
                                }
                              },
                              [
                                _c("h3", [
                                  _vm._v(_vm._s(_vm.$t("Button 2 (Sub)")))
                                ])
                              ]
                            ),
                            _c("v-select", {
                              staticClass: "select-column ml-3",
                              staticStyle: { color: "black" },
                              attrs: {
                                items: _vm.getButtonsOptions,
                                "item-text": "name",
                                "item-value": "value",
                                placeholder: "Select (Sub)",
                                outlined: "",
                                dense: ""
                              },
                              model: {
                                value: _vm.buttonInfo.sub,
                                callback: function($$v) {
                                  _vm.$set(_vm.buttonInfo, "sub", $$v)
                                },
                                expression: "buttonInfo.sub"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "var(--font14)",
                                  color: "#001e38",
                                  margin: "0 0 6px 14px"
                                }
                              },
                              [
                                _c("h3", [
                                  _vm._v(_vm._s(_vm.$t("Button 3 (Extend 1)")))
                                ])
                              ]
                            ),
                            _c("v-select", {
                              staticClass: "select-column ml-3",
                              staticStyle: { color: "black" },
                              attrs: {
                                items: _vm.getButtonsOptions,
                                "item-text": "name",
                                "item-value": "value",
                                placeholder: "Select (Extend 1)",
                                outlined: "",
                                dense: ""
                              },
                              model: {
                                value: _vm.buttonInfo.extend1,
                                callback: function($$v) {
                                  _vm.$set(_vm.buttonInfo, "extend1", $$v)
                                },
                                expression: "buttonInfo.extend1"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "var(--font14)",
                                  color: "#001e38",
                                  margin: "0 0 6px 14px"
                                }
                              },
                              [
                                _c("h3", [
                                  _vm._v(_vm._s(_vm.$t("Button 4 (Extend 2)")))
                                ])
                              ]
                            ),
                            _c("v-select", {
                              staticClass: "select-column ml-3",
                              staticStyle: { color: "black" },
                              attrs: {
                                items: _vm.getButtonsOptions,
                                "item-text": "name",
                                "item-value": "value",
                                placeholder: "Select (Extend 2)",
                                outlined: "",
                                dense: ""
                              },
                              model: {
                                value: _vm.buttonInfo.extend2,
                                callback: function($$v) {
                                  _vm.$set(_vm.buttonInfo, "extend2", $$v)
                                },
                                expression: "buttonInfo.extend2"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn ml-6",
                            attrs: { disabled: _vm.btnDisabled, text: "" },
                            on: { click: _vm.saveSelectedOptions }
                          },
                          [_vm._v(_vm._s(_vm.$t("Save")) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("h2", { staticClass: "page-title-bar mt-7" }, [
        _c("i", { staticClass: "ico ico-system" }),
        _vm._v(_vm._s(_vm.$t("Label Properties")) + " ")
      ]),
      _c(
        "div",
        [
          _c("v-row", { staticStyle: { "margin-top": "10px" } }, [
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "searchArea" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("v-text-field", {
                      staticClass: "form-input ml-3 col mandatory upper-case",
                      attrs: {
                        label: _vm.$t("Label Code") + "*",
                        placeholder: "" + _vm.$t("Input The Label Code"),
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.labelCode,
                        callback: function($$v) {
                          _vm.labelCode = $$v
                        },
                        expression: "labelCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col pl-3 pr-0" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn type-search ml-3",
                        attrs: {
                          text: "",
                          disabled:
                            _vm.labelCode === "" ||
                            _vm.labelCode === undefined ||
                            _vm.labelCode === null
                        },
                        on: { click: _vm.getLabelsTypeInfo }
                      },
                      [_vm._v(_vm._s(_vm.$t("Search")) + " ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col pl-0" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn type-search ml-3",
                        attrs: { text: "" },
                        on: { click: _vm.clearSearch }
                      },
                      [_vm._v(_vm._s(_vm.$t("Clear")) + " ")]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "700", persistent: "" },
          on: {
            "click:outside": function($event) {
              _vm.labelDetailsPopup = false
            }
          },
          model: {
            value: _vm.labelDetailsPopup,
            callback: function($$v) {
              _vm.labelDetailsPopup = $$v
            },
            expression: "labelDetailsPopup"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "cornerClose",
                  attrs: { icon: "", dark: "", "x-small": "" },
                  on: {
                    click: function($event) {
                      _vm.labelDetailsPopup = false
                    }
                  }
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("mdi-close")])],
                1
              ),
              _c("div", { staticClass: "popup-titbox align-items-center" }, [
                _c("h3", { staticClass: "page-title-bar" }, [
                  _c("i", { staticClass: "ico ico-template" }),
                  _vm._v(" " + _vm._s(_vm.$t("Label Properties")) + " ")
                ]),
                _c("div", { staticClass: "spacer" })
              ]),
              _c(
                "div",
                { staticClass: "v-data-table tbl-type01 mt-6 theme--light" },
                [
                  _c("div", { staticClass: "v-data-table__wrapper" }, [
                    _c(
                      "table",
                      { staticClass: "table" },
                      _vm._l(_vm.labelDetails, function(value, name) {
                        return _c("tr", { key: name }, [
                          _c("td", { attrs: { width: "30%" } }, [
                            _c("strong", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("removeSpacefromCapitalLetter")(
                                      _vm._f("camelCaseToProperCase")(name)
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c("td", [_vm._v(_vm._s(value))])
                        ])
                      }),
                      0
                    )
                  ])
                ]
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.labelDetailsPopup = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }